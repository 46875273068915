import { useTracker } from "@ctv/core";
import { ButtonSeo, ButtonSeoProps } from "@ctv/shared-core/src";
import { TrackingType } from "@ctv/shared/tracking/types";

type Props = ButtonSeoProps & {
  /**
   * To add data tracking if there are any (on click)
   */
  track?: TrackingType;
};
export default function TrackingButtonSeo(props: Props) {
  const { track, onPress, ...rest } = props;

  const doTracking = useTracker();

  function handlePress() {
    if (track) doTracking(track.event, track.data);
    if (typeof onPress === "function") onPress();
  }

  return <ButtonSeo {...rest} onPress={handlePress} />;
}
