import { ReactNode } from "react";
import { TouchableOpacity } from "react-native";

import { TextSeo, Token, ViewSeo, useHoverable } from "@ctv/shared-core/src";
import cssStyle from "./menuItem.module.css";

type MenuProps = {
  testID?: string;
  isActive?: boolean;
  onPress?(): void;
  text: string;
  textClassName?: string;
  className?: string;
  activeClassName?: string;
};

type MenuPropsWithLeftIcon = MenuProps & {
  LeftIcon: ReactNode;
  LeftIconActive: ReactNode;
};

type MenuPropsWithLRightIcon = MenuProps & {
  RightIcon: ReactNode;
  RightIconActive: ReactNode;
};

type MenuPropsWithIcons = MenuProps & {
  LeftIcon: ReactNode;
  LeftIconActive: ReactNode;
  RightIcon: ReactNode;
  RightIconActive: ReactNode;
};

type Props =
  | MenuProps
  | MenuPropsWithLeftIcon
  | MenuPropsWithLRightIcon
  | MenuPropsWithIcons;

export default function MenuItem(props: Props) {
  const {
    testID,
    isActive,
    onPress,
    text,
    textClassName,
    className,
    activeClassName,
  } = props;

  const textStyle =
    textClassName ?? (isActive ? cssStyle.active : cssStyle.inactive);

  const [isHovered, eventHandlers] = useHoverable();
  const hoveredStyle = isHovered && {
    backgroundColor: String(
      Token.opacity.washedOut(Token.color.lightSecondary)
    ),
  };
  const activeStyle = isActive && (activeClassName || cssStyle.activeBackdrop);

  return (
    <TouchableOpacity testID={testID} activeOpacity={0.5} onPress={onPress}>
      <ViewSeo
        {...eventHandlers}
        className={[cssStyle.menu, hoveredStyle, activeStyle, className].join(
          " "
        )}
      >
        {"LeftIcon" in props && (
          <ViewSeo className={cssStyle.leftIcon}>
            {isActive ? props.LeftIconActive : props.LeftIcon}
          </ViewSeo>
        )}
        <TextSeo className={textStyle}>{text}</TextSeo>
        {"RightIcon" in props && (
          <ViewSeo className={cssStyle.rightIcon}>
            {isActive ? props.RightIconActive : props.RightIcon}
          </ViewSeo>
        )}
      </ViewSeo>
    </TouchableOpacity>
  );
}
