import { FC, useState } from "react";
import { StyleSheet } from "react-native";

import { useAuth, useContentResource } from "@ctv/core";

import {
  ButtonProps,
  ButtonSeo,
  IconKitProps,
  Locale,
  Radio,
  RadioGroup,
  TextSeo,
  Token,
  Tray,
  useChangeLocale,
  useLocale,
  ViewSeo,
} from "@ctv/shared-core/src";

import FlagEnIcon from "@traveloka/icon-kit-web/react/IcFlagCircularEn";
import FlagIdIcon from "@traveloka/icon-kit-web/react/IcFlagCircularId";
import FlagThIcon from "@traveloka/icon-kit-web/react/IcFlagCircularTh";
import FlagVnIcon from "@traveloka/icon-kit-web/react/IcFlagCircularVn";

import cssStyles from "./LanguageMenu.module.css";

const iconMap: Record<string, FC<IconKitProps>> = {
  [Locale.ENID]: FlagEnIcon,
  [Locale.IDID]: FlagIdIcon,
  [Locale.ENTH]: FlagEnIcon,
  [Locale.THTH]: FlagThIcon,
  [Locale.ENVN]: FlagEnIcon,
  [Locale.VNVN]: FlagVnIcon,
};

const langMap: Record<string, string> = {
  [Locale.ENID]: "EN",
  [Locale.ENTH]: "EN",
  [Locale.ENVN]: "EN",
  [Locale.IDID]: "ID",
  [Locale.THTH]: "TH",
  [Locale.VNVN]: "VN",
};

const buttonTextInk: ButtonProps["textInk"] = {
  normal: "black-primary",
  disabled: "black-muted",
  hovered: "black-secondary",
};

export default function LanguageMenu() {
  const [visible, setVisible] = useState(false);
  const locale = useLocale();

  const FlagIcon = iconMap[locale];

  function onLanguagePress() {
    setVisible((prev) => !prev);
  }

  return (
    <>
      <ButtonSeo
        buttonClassName={cssStyles.langButton}
        startIcon={
          <FlagIcon width={24} height={24} color={Token.color.bluePrimary} />
        }
        text={langMap[locale]}
        textInk={buttonTextInk}
        onPress={onLanguagePress}
        variant="secondary"
        size="small"
      />
      <Tray visible={visible} onClose={onLanguagePress}>
        <LanguageRadio onClose={onLanguagePress} />
      </Tray>
    </>
  );
}

function LanguageRadio(props: { onClose(): void }) {
  const locale = useLocale();
  const { user } = useAuth();

  const [localLocale, setLocalLocale] = useState(locale);

  const doChangeLocale = useChangeLocale();
  const cr = useContentResource().CorporateLanguage;

  const menusEN = (locale: Locale) => ({
    text: cr.englishText,
    name: locale,
  });

  const menusID = {
    text: cr.indonesiaText,
    name: Locale.IDID,
  };

  const menusTH = {
    text: cr.thailandText,
    name: Locale.THTH,
  };

  const menusVN = {
    text: cr.vietnamText,
    name: Locale.VNVN,
  };

  function getMenus(country?: string) {
    switch (country) {
      case "TH":
        return [menusEN(Locale.ENTH), menusTH];
      case "VN":
        return [menusEN(Locale.ENVN), menusVN];
      case "ID":
        return [menusEN(Locale.ENID), menusID];
      default:
        return [menusEN(Locale.ENID), menusID];
    }
  }

  function handleChangeLanguage() {
    doChangeLocale(localLocale);
    props.onClose();
  }

  return (
    <ViewSeo spacing="xs">
      <TextSeo className={cssStyles.title} variant="title-2">
        {cr.title}
      </TextSeo>
      <RadioGroup
        defaultValue={locale}
        // Prevent typing error by TypeScript
        onChange={(locale) => setLocalLocale(locale)}
        value={localLocale}
      >
        {getMenus(user?.country).map((menu) => (
          <Radio key={menu.name} style={styles.radio} value={menu.name}>
            {menu.text}
          </Radio>
        ))}
      </RadioGroup>
      <ButtonSeo
        className={cssStyles.button}
        variant="primary"
        text={cr.selectButtonText}
        onPress={handleChangeLanguage}
      />
    </ViewSeo>
  );
}

const styles = StyleSheet.create({
  radio: {
    padding: Token.spacing.s,
  },
});
