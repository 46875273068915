"use client";
import { useState } from "react";
import { TouchableOpacity } from "react-native";

import { useAuth, useContentResource, useFeatureControl } from "@ctv/core";
import { ORS_DEPRECATION_FC_KEY } from "@ctv/core/feature-control/constants";
import {
  FullScreenDialog,
  Image,
  TextSeo,
  Token,
  useLocalizedRouterServer,
  ViewSeo,
} from "@ctv/shared-core/src";
import TrackingButtonSeo from "@ctv/shared/components/Tracking/TrackingButtonSeo";
import TrackingLink from "@ctv/shared/components/Tracking/TrackingLink";
import {
  HeaderTrackingPage,
  trackFeatureButtonClick,
  trackLoginButtonClick,
  trackProductButtonClick,
  trackSignUpButtonClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";

import IcSystemCrossClose from "@traveloka/icon-kit-web/react/IcSystemCrossClose";
import IcSystemMenuHamburger from "@traveloka/icon-kit-web/react/IcSystemMenuHamburger";

import styles from "./HamburgerMenu.module.css";

type Props = {
  track?: HeaderTrackingPage;
};

export default function HamburgerMenu(props: Props) {
  const { track } = props;

  const [visible, setVisible] = useState(false);
  const { enabled, properties } = useFeatureControl<{
    "google-form-link": string;
  }>(ORS_DEPRECATION_FC_KEY);

  const router = useLocalizedRouterServer();
  const auth = useAuth();

  const cr = useContentResource().CorporateRevampHomePage;

  const menu = [
    {
      text: cr.featuresText,
      href: "/features",
      track: trackFeatureButtonClick(track),
    },
    {
      text: cr.productsText,
      href: "/products",
      track: trackProductButtonClick(track),
    },
  ];

  function onMenuPress() {
    setVisible((prev) => !prev);
  }

  function handleLogin() {
    auth.login();
  }

  function handleRegister() {
    if (enabled) {
      window.location.href = properties["google-form-link"];
      return;
    }
    router.push("/register");
  }

  return (
    <>
      <TouchableOpacity activeOpacity={0.5} onPress={onMenuPress}>
        <IcSystemMenuHamburger
          color={Token.color.darkPrimary}
          accentColor={Token.color.bluePrimary}
        />
      </TouchableOpacity>
      <FullScreenDialog visible={visible}>
        <ViewSeo className={styles.container}>
          <ViewSeo
            className={styles.header}
            row
            align="end"
            justify="between"
            spacing="m"
          >
            <Image
              className={styles.image}
              src="/images/traveloka-for-corporates.png"
              alt="logo"
              height={30}
            />
            <TouchableOpacity onPress={onMenuPress}>
              <IcSystemCrossClose color={Token.color.darkSecondary} />
            </TouchableOpacity>
          </ViewSeo>
          <ViewSeo className={styles.content} justify="between">
            <ViewSeo>
              {menu.map((item, index) => (
                <TrackingLink
                  key={index}
                  className={styles.menu}
                  href={item.href}
                  track={item.track}
                >
                  <TextSeo variant="title-1">{item.text}</TextSeo>
                </TrackingLink>
              ))}
            </ViewSeo>
            <ViewSeo row justify="between" spacing="xs">
              <TrackingButtonSeo
                className={styles.button}
                variant="secondary"
                text={cr.loginButtonText}
                onPress={handleLogin}
                track={trackLoginButtonClick(track)}
              />
              <TrackingButtonSeo
                className={styles.button}
                variant="main-cta"
                text={cr.signUpButtonText}
                onPress={handleRegister}
                track={trackSignUpButtonClick(track)}
              />
            </ViewSeo>
          </ViewSeo>
        </ViewSeo>
      </FullScreenDialog>
    </>
  );
}
