"use client";
import { useEffect } from "react";

import locationHistory from "@ctv/shared-core/src/classes/LocationHistory";

type Props = {
  Component: React.JSX.Element;
};

export default function WithLocationHistory({ Component }: Props) {
  useEffect(() => {
    locationHistory.add(window.location.pathname);
  }, []);

  return <>{Component}</>;
}

/*
    This is used to separate the client-side logic from the server-side logic.
    And to allow the hoc `withLocationHistory` to be invoked on the server-side.
*/
