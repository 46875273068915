import { useTracker } from "@ctv/core";
import { LocalizedLink } from "@ctv/shared-core/src";
import { LocalizedLinkProps } from "@ctv/shared-core/src/core/locale/components/LocalizedLink";
import { TrackingType } from "@ctv/shared/tracking/types";
import Link from "next/link";

import cssStyles from "./trackingLink.module.css";

type Props = LocalizedLinkProps & {
  localized?: boolean;
  track?: TrackingType;
};

export default function TrackingLink(props: Props) {
  const { track, localized = true, onClick, ...rest } = props;

  const doTracking = useTracker();
  const Component = localized ? LocalizedLink : Link;

  return (
    <Component
      className={cssStyles.trackingLink}
      {...rest}
      onClick={(e) => {
        if (track) {
          doTracking(track.event, track.data);
        }
        onClick?.(e);
      }}
    />
  );
}
