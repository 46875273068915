import { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import { Token } from "@ctv/shared-core/src";

type Props = PropsWithChildren<{
  light?: boolean;
}>;

export default function Page(props: Props) {
  const { children, light = true } = props;

  return (
    <View style={[styles.container, light ? styles.light : styles.dark]}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // @ts-expect-error -- vh typing does not exist in React Native
    minHeight: "100vh",
    minWidth: 1080,
  },
  light: {
    backgroundColor: Token.color.lightPrimary,
  },
  dark: {
    backgroundColor: Token.color.lightNeutral,
  },
});
