"use client";
import Link from "next/link";

import { useContentResource } from "@ctv/core";
import {
  ButtonSeo,
  DividerSeo,
  Image,
  LocalizedLink,
  TextSeo,
  Token,
  useLocale,
  ViewSeo,
} from "@ctv/shared-core/src";

import MailIcon from "@traveloka/icon-kit-web/react/IcContactMail";
import PhoneIcon from "@traveloka/icon-kit-web/react/IcContactPhone";
import IcSystemArrowRight12 from "@traveloka/icon-kit-web/react/IcSystemArrowRight12";

import styles from "./Footer.module.css";

export default function Footer() {
  const cr = useContentResource().CorporateRevampFooter;
  const locale = useLocale();

  return (
    <ViewSeo className={styles.container}>
      <ViewSeo spacing="l">
        <ViewSeo className={styles.footerInfoContainer} spacing="xl">
          <ViewSeo spacing="s">
            <TextSeo variant="title-2" ink="white-primary">
              {cr.ourOfficeText}
            </TextSeo>
            <TextSeo ink="white-secondary" variant="ui-small">
              {cr.addressText}
            </TextSeo>
            <Link
              className={styles.link}
              href={"https://goo.gl/maps/SFSiT7TbhZUCaTzV9"}
              target="_blank"
            >
              <ButtonSeo
                className={styles.button}
                size="small"
                variant="text-white"
                text={cr.getDirectionText}
                endIcon={
                  <IcSystemArrowRight12
                    color={Token.color.lightPrimary}
                    width={12}
                    height={12}
                  />
                }
              />
            </Link>
          </ViewSeo>
          <ViewSeo spacing="s">
            <TextSeo variant="title-2" ink="white-primary">
              {cr.othersText}
            </TextSeo>
            <LocalizedLink
              className={styles.link}
              href="/terms-and-conditions"
              target="_blank"
            >
              <TextSeo ink="white-secondary" variant="ui-small">
                {cr.termsAndConditionsText}
              </TextSeo>
            </LocalizedLink>
            <Link
              className={styles.link}
              href={`https://www.traveloka.com/${locale}/privacy-notice`}
              target="_blank"
            >
              <TextSeo ink="white-secondary" variant="ui-small">
                {cr.privacyNoticeText}
              </TextSeo>
            </Link>
          </ViewSeo>

          <ViewSeo spacing="s">
            <TextSeo variant="title-2" ink="white-primary">
              {cr.needHelpText}
            </TextSeo>
            <ViewSeo row spacing="xs" align="center">
              <PhoneIcon
                color={Token.color.lightSecondary}
                accentColor={Token.color.lightSecondary}
                width={20}
                height={20}
              />
              <a className={styles.link} href={`tel:${cr.companyPhoneNumber}`}>
                <TextSeo variant="ui-small" ink="white-secondary">
                  {cr.companyPhoneNumber}
                </TextSeo>
              </a>
            </ViewSeo>
            <ViewSeo row spacing="xs" align="center">
              <MailIcon
                color={Token.color.lightSecondary}
                accentColor={Token.color.lightSecondary}
                width={20}
                height={20}
              />
              <a className={styles.link} href={`mailto:${cr.companyEmail}`}>
                <TextSeo variant="ui-small" ink="white-secondary">
                  {cr.companyEmail}
                </TextSeo>
              </a>
            </ViewSeo>
          </ViewSeo>
        </ViewSeo>

        <DividerSeo />
        <Image
          className={styles.logo}
          src="/images/traveloka-for-corporates-white.png"
          alt="tvlk white logo"
          height={30}
          width={231}
        />
        <TextSeo variant="ui-tiny" ink="white-primary">
          {cr.copyrightText}
        </TextSeo>
      </ViewSeo>
    </ViewSeo>
  );
}
