"use client";
import { TouchableOpacity } from "react-native";

import { useAuth, useContentResource, useFeatureControl } from "@ctv/core";
import { ORS_DEPRECATION_FC_KEY } from "@ctv/core/feature-control/constants";
import {
  ButtonSeo,
  Image,
  TextSeo,
  useHover,
  ViewSeo,
} from "@ctv/shared-core/src";
import LanguageMenuSeo from "@ctv/shared/components/LanguageMenu/LanguageMenuSeo";
import { useHeaderHeight } from "@ctv/shared/components/Layout/HeaderHeightContext";
import TrackingButtonSeo from "@ctv/shared/components/Tracking/TrackingButtonSeo";
import TrackingLink from "@ctv/shared/components/Tracking/TrackingLink";
import {
  HeaderTrackingPage,
  trackArticleButtonClick,
  trackFeatureButtonClick,
  trackLoginButtonClick,
  trackProductButtonClick,
  trackSignUpButtonClick,
  trackTvlkImageClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";

import styles from "./header.module.css";

type Props = {
  track?: HeaderTrackingPage;
};

export default function Header(props: Props) {
  const { track } = props;

  const { enabled, properties } = useFeatureControl<{
    "google-form-link": string;
  }>(ORS_DEPRECATION_FC_KEY);

  const [featureHover, featureHandlers] = useHover();
  const [productHover, productHandlers] = useHover();
  const [articleHover, articleHandlers] = useHover();
  const [_, setHeight] = useHeaderHeight();
  const auth = useAuth();

  const cr = useContentResource().CorporateRevampHomePage;

  return (
    <ViewSeo
      ref={(div) => {
        setHeight(div?.getBoundingClientRect().height);
      }}
      className={[styles.flexBox, styles.header].join(" ")}
    >
      <ViewSeo className={styles.flexBox}>
        <TrackingLink href={"/"} track={trackTvlkImageClick(track)}>
          <Image
            src="/images/traveloka-for-corporates.png"
            alt="Traveloka for Corporates"
            width={282}
          />
        </TrackingLink>

        <TrackingLink
          {...featureHandlers}
          href={"/features"}
          track={trackFeatureButtonClick(track)}
        >
          <TouchableOpacity>
            <TextSeo
              ink={featureHover ? "black-secondary" : "black-primary"}
              variant="ui-baseline"
              className={styles.nav}
            >
              {cr.featuresText}
            </TextSeo>
          </TouchableOpacity>
        </TrackingLink>
        <TrackingLink
          {...productHandlers}
          href={"/products"}
          track={trackProductButtonClick(track)}
        >
          <TouchableOpacity>
            <TextSeo
              ink={productHover ? "black-secondary" : "black-primary"}
              variant="ui-baseline"
              className={styles.nav}
            >
              {cr.productsText}
            </TextSeo>
          </TouchableOpacity>
        </TrackingLink>
        <TrackingLink
          {...articleHandlers}
          href={"/articles/?page=1"}
          track={trackArticleButtonClick(track)}
        >
          <TouchableOpacity>
            <TextSeo
              ink={articleHover ? "black-secondary" : "black-primary"}
              variant="ui-baseline"
              className={styles.nav}
            >
              {cr.articlesText}
            </TextSeo>
          </TouchableOpacity>
        </TrackingLink>
      </ViewSeo>
      <ViewSeo className={styles.flexBox}>
        <LanguageMenuSeo />
        <TrackingButtonSeo
          variant="secondary"
          text={cr.loginButtonText}
          className={styles.loginButton}
          onPress={auth.login}
          track={trackLoginButtonClick(track)}
        />
        <TrackingLink
          localized={!enabled}
          href={enabled ? properties["google-form-link"] : "/register"}
          track={trackSignUpButtonClick(track)}
        >
          <ButtonSeo variant="main-cta" text={cr.signUpButtonText} />
        </TrackingLink>
      </ViewSeo>
    </ViewSeo>
  );
}
